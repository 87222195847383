<template>
    <div>
        <columns>
            <column>
                <h4 class="title is-4">Team Segmentation</h4>
                <p class="has-text-grey">
                    You may optionally segement this classification by assigning it to specific teams.
                    This will ensure that only the assigned teams will have access to this classification.
                </p>
            </column>
        </columns>
        <columns>
            <column>
                <data-selector classes="is-medium is-rounded" searchable multiple value-key="uuid" label-key="name"
                    :items="team_list" :value="equipment_classification.segmented_teams" @input="updateTeams">
                    Segmented Teams
                </data-selector>
            </column>
        </columns>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {

    methods: {
        updateTeams(teams) {
            this.$store.commit('equipmentClassification/updateSegementedTeams', teams)
        }
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification'
        ]),
        ...mapGetters('team', [
            'team_list'
        ])
    }

}
</script>