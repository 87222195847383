<template>
    <div>
        <columns>
            <column class="is-6 is-flex is-align-items-center">
            {{ checklist.name }}
        </column>
        <column class="has-text-right">
            <p class="is-block">Type</p>
            <p class="is-block has-text-info">{{ checklist.classification ? checklist.classification.name : 'Unclassified' }}</p>
        </column>
        <column class="is-2 has-text-right">
            <p class="is-block">Checkpoints</p>
            <p class="is-block has-text-info">{{ checklist.checkpoints.length }}</p>
        </column>
        <column class="is-narrow">
            <number-input 
                :precision="0"
                v-model="days"
                classes="is-small is-rounded"
                placeholder="In days"
                hint="If required you may set a regular interval at which this checklist should be carried out. Set in days">
                Repeat Interval
            </number-input>
        </column>
        <column class="is-1 is-flex is-align-items-center is-justify-content-end">
            <action-button 
                class="is-small is-danger is-rounded" 
                @click="removeChecklistTemplate(checklist)">
                Remove
            </action-button>
        </column>
        </columns>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    
    props: {
        checklist: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        days: 0
    }),

    created() {
        this.days = this.checklist.pivot.interval
    },

    methods: {
        async removeChecklistTemplate(checklist) {
            if(await this.$confirm({
                title: 'Remove Checklist Template',
                message: 'Are you sure you want to remove this checklist template?'
            })) {
                this.$store.commit('equipmentClassification/removeChecklistTemplate', checklist)
            }
        },
        updateChecklistInterval(interval) {
            this.$store.commit('equipmentClassification/updateChecklistInterval', {
                checklist: this.checklist,
                interval
            })
        },
        setDays(days) {
            this.updateChecklistInterval(days)
        }
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification'
        ])
    },

    watch: {
        'days': 'setDays'
    }

}
</script>