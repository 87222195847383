<template>
    <div>
        <columns>
            <column>
                <h5 class="title is-4">
                    Checklist Templates <small class="has-text-weight-light">(optional)</small>
                </h5>    
                <h6 class="subtitle is-6 has-text-grey">
                    If you choose not to assign any checklists to this Equipment Classification <em>all unclassified</em> 
                    Checklist Templates will be made available to it by default. 
                    However, once you assign a Checklist Templates to an Equipment Classification, 
                    those then become the only templates available to it.
                </h6>
            </column>
            <column class="is-narrow">
                <div class="buttons has-addons">
                    <action-button 
                        @click="openCreateChecklist"
                        class="is-ghost is-small is-rounded"
                        left-icon="plus">
                        Checklist Template
                    </action-button>
                    <action-button 
                        @click="openChecklistSelector"
                        class="is-warning is-rounded is-small"
                        left-icon="search">
                        Select Checklist Templates
                    </action-button>
                </div>
            </column>
        </columns>
        <columns>
            <column>
                <div class="box p-5">
                    <p v-if="!equipment_classification.checklists.length" class="has-text-centered">
                        There are no Checklist Templates associated with this Equipment Classification.
                    </p>
                    <div class="mt-5" v-else>
                        <checklist-row :checklist="checklist" v-for="checklist in equipment_classification.checklists" :key="checklist.id" />
                    </div>
                </div>
            </column>
        </columns>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ChecklistRow from './ChecklistRow.vue'
import { selectChecklists, quickChecklistTemplate } from '@/modals'
import { checklist as backend } from '@/api'

export default {
  components: { 
        ChecklistRow
    },
    
    data: () => ({
        checklist_templates: [],
        capturing_meta: false,
        metadata_types: [
            { label: 'Free Text', value: 'text' },
            { label: 'Numeric', value: 'number' },
            { label: 'Date Picker', value: 'date' },
        ]
    }),

    async beforeCreate() {
        await backend.loadList(null, ({ data }) => {
            this.checklist_templates = data
        }, () => { })
        this.loading = false
    },

    methods: {
        ...mapMutations('equipmentClassification', [
            'addChecklist',
            'removeChecklistTemplate'
        ]),
        openChecklistSelector() {
            selectChecklists(this.equipment_classification, this.checklist_templates)
        },
        openCreateChecklist() {
            quickChecklistTemplate().then(checklist => {
                if(checklist) {
                    this.addChecklist(checklist)
                }
            }) 
        }
    },

    computed: mapGetters('equipmentClassification', [
        'equipment_classification'
    ])

}
</script>