<template>
<loader v-bind="{ loading }">
    <form>
        <columns>
            <column>
                <text-input
                    classes="is-medium is-rounded"
                    placeholder="Eg. Delivery Van"
                    required
                    :error="$root.errors.name"
                    :value="equipment_classification.name"
                    @input="updateName">
                    Name
                </text-input>
            </column>
            <column class="is-narrow is-flex is-align-items-end">
                <action-button 
                    @click="duplicate"
                    :working="duplicating"
                    class="is-rounded"
                    left-icon="copy" left-icon-type="far">
                    Copy
                </action-button>
            </column>
        </columns>
        <team-segmentation />
        <meta-data-builder />
        <checklist-assignment />
        <columns>
            <column>
                <submit-button
                    class="is-rounded is-medium mt-3"
                    :working="working"
                    @submit="update">
                    Save
                </submit-button>
            </column>
            <column class="is-narrow">
                <action-button
                    class="is-rounded is-danger is-small mt-3"
                    :disabled="working"
                    :working="deleting"
                    icon="trash"
                    @click="deleteClassification">
                    Delete
                </action-button>
            </column>
        </columns>
    </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ChecklistAssignment from './partials/ChecklistAssignment.vue'
import MetaDataBuilder from './partials/MetaDataBuilder.vue'
import TeamSegmentation from './segmentation/TeamSegmentation.vue'

export default {
    components: {
        MetaDataBuilder,
        ChecklistAssignment,
        TeamSegmentation
    },

    data: () => ({
        loading: true,
        working: false,
        deleting: false,
        duplicating: false,
    }),

    async beforeCreate() {
        await this.$store.dispatch('team/loadTeamList')
        await this.$store.dispatch('equipmentClassification/load', this.$route.params.equipmentClassification)
        this.loading = false
    },

    methods: {
        ...mapMutations('equipmentClassification', [
            'updateName',
        ]),
        update() {
            this.working = true
            this.$store.dispatch('equipmentClassification/update').then(() => {
                this.$toast.success('Saved')
                this.working = false
            }).catch(() => this.working = false)
        },

        duplicate() {
            this.duplicating = true
            this.$store.dispatch('equipmentClassification/duplicate').then( async (data) => {
                this.$toast.success('Success')
                await this.$store.dispatch('equipmentClassification/load', data.uuid)
                this.$router.push({
                    name: 'equipment-classification-manager',
                    params: {
                        equipmentClassification: data.uuid
                    }
                })
                this.duplicating = false
            }).catch(() => {
                this.duplicating = false
            })
        },

        async deleteClassification() {

            if(this.equipment_classification.equipment_count) {
                return this.$toast.error('Cannot delete this classification as it still has equipment assigned to it.')
            }

            if(await this.$confirm({
                title: 'Delete Equipment Classification',
                message: 'Are you sure you want to delete this classification?'
            })) {
                this.deleting = true
                this.$store.dispatch('equipmentClassification/deleteClassification').then(() => {
                    this.$toast.success('Successfully deleted')
                    this.$router.push({
                        name: 'equipment-classifications'
                    })
                    this.deleting = false
                }).catch((error) => {
                    console.log(error)
                    this.$toast.error('We were unable to delete this classification please contact support for assistance')
                    this.deleting = false
                })
            }
        }
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification'
        ])
    }

}
</script>